<template>
  <CommonPage id="terms" title="Terms of Use">
    <section class="terms-content">
      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          Welcome to our news and information platform! By accessing or using our services, you
          agree
          to comply with and be bound by the following Terms of Service. Please read these terms
          carefully before using our platform.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Use of Services
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          You agree to use our news and information platform solely for lawful purposes and in
          compliance with all applicable laws and regulations. You must not engage in any activity
          that interferes with or disrupts the functioning of the platform or its services.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          User Accounts
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          To access certain features of our platform, you may need to create a user account. You are
          responsible for maintaining the confidentiality of your account credentials and for all
          activities that occur under your account.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Content
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          Our platform may include user-generated content, third-party content, and proprietary
          content owned or licensed by us. You agree not to reproduce, distribute, modify, or create
          derivative works of any content without prior authorization.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Intellectual Property
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          All intellectual property rights in our platform, including trademarks, copyrights, and
          proprietary information, belong to us or our licensors. You may not use our intellectual
          property without our express written permission.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Third-Party Links
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          Our platform may contain links to third-party websites or services. We are not responsible
          for the content, privacy practices, or actions of third parties and recommend reviewing
          their terms and policies.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Disclaimer of Warranties
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          Our platform is provided on an &quot;as is&quot; and &quot;as available&quot; basis
          without
          warranties of any kind, either express or implied. We do not guarantee the accuracy,
          completeness, or reliability of any information or content on the platform.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Limitation of Liability
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          In no event shall we be liable for any direct, indirect, incidental, special, or
          consequential damages arising out of or in connection with your use of our platform. This
          includes damages for loss of profits, data, or goodwill.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Indemnification
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          You agree to indemnify and hold us harmless from any claims, damages, liabilities, or
          expenses arising out of your use of our platform or any violation of these Terms of
          Service.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Governing Law
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          These Terms of Service are governed by the laws of Singapore, without regard to its
          conflict of laws principles. Any disputes arising from these terms shall be subject to the
          exclusive jurisdiction of the courts in Singapore.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Copyright Policy
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          {{ domainConfig?.title =='NEWS'?formattedHostName:domainConfig.title }} respects the
          intellectual property rights of others and asks that users of our
          Services do the same. If you believe any Content accessible on or from the Services
          infringe your copyright, you may request removal of that Content (or access to it) from
          the Services by submitting written notification to our copyright agent designated below.
          The written notification must include (pursuant to the Digital Millennium Copyright Act,
          17 U.S.C. § 512):<br>
        </p>
        <div class="c-legal_list-wrap">
          <ul role="list" class="c-legal_list">
            <li>
              An electronic or physical signature of the owner or of the person authorized to act on
              behalf of the owner of the copyright interest.
            </li>
            <li>Identification of the copyrighted work claimed to have been infringed.</li>
            <li>
              A description of the Content on the Services that you claim is infringing, and
              information reasonably sufficient to permit us to locate the material (for example,
              the
              URL of the webpage).
            </li>
            <li>Your name, address, telephone number, and, if available, email address.</li>
            <li>
              A statement that you have a good faith belief that the disputed use is not authorized
              by the copyright owner, its agent, or the law.
            </li>
            <li>
              A statement by you, made under penalty of perjury, that the above information in your
              notice is accurate and that you are the copyright owner or authorized to act on the
              copyright owner’s behalf.
            </li>
          </ul>
        </div>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Changes to Terms
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          We reserve the right to update or modify these Terms of Service at any time. Changes will
          be effective upon posting on the platform. Your continued use of the platform after
          changes
          signifies your acceptance of the revised terms.
        </p>

        <p class="c-text_legal">
          If you have any questions or concerns about these Terms of Service, please contact us at
          [{{ formattedHost }}_support@gmail.com].
        </p>

        <p class="c-text_legal">
          We respect intellectual property rights and are committed to sharing valuable information.
          Any unauthorized reproduction is prohibited.
        </p>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import '@/css/news/privacytermspc.scss';
import CommonPage from '@/pages/CommonPagePC.vue';
import { mapGetters } from 'vuex'

export default {
  components: {
    CommonPage
  },
  computed: {
    ...mapGetters({
      host: 'host',
      domainConfig: 'domainConfig',
    }),
    formattedHostName () {
      if (!this.host) return 'News';
      const parts = this.host.split('.');
      if (parts.length > 0) {
        const firstPart = parts[0];
        return firstPart.charAt(0).toUpperCase() + firstPart.slice(1) + ' Media';
      }
      return '';
    },
    formattedHost () {
      if (!this.host) return 'news_media';
      const parts = this.host.split('.');
      if (parts.length > 0) {
        const firstPart = parts[0];
        return firstPart;
      }
      return 'news_media';
    }
  },
};
</script>
