<template>
  <section :id="id"
    :class="`detect_deviece_${deviceType} state_${deviceType} page_is_${themeConfig.isArabia==1?'ar':'en'}`">
    <component :is="`navModule_4`" :host="host" :themeConfig="themeConfig"
      :domainConfig="domainConfig" @keyWordSearch="handleSearch" />
    <slot />
    <FootModuleLoader :themeConfig="themeConfig" :host="host" :deviceType="deviceType" />
  </section>
</template>

<script>
import NavBar from '@/pages/components/NavBar.vue';
import FootModuleLoader from '@/pages/components/FootModuleLoader.vue';
import { mapGetters } from 'vuex';
const navModule_4 = () => import('@/pages/modules/nav/navModule_4.vue') //代码切割

export default {
  components: {
    NavBar,
    navModule_4,
    FootModuleLoader
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'deviceType',
      'host',
      'domainConfig'
    ]),
    ...mapGetters({
      themeConfig: 'themeConfig'
    })
  },
  methods: {
    handleSearch (data) {
      console.log(data);
      // this.$router.push({ name: 'home', params: { restart: 'search' } }); //TODO:
      this.$router.push({ name: 'search', query: { keyword: this.query } });
    },
    handleMenuItem (data) {
      console.log(data);
      const processedData = data.split('.my')[0];
      console.log(processedData);
      window.location.href = `/${processedData}`;
    },
    goback () {
      this.$router.go(-1);
    }
  },
};
</script>